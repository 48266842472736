<template>
  <div v-if="signedIn" id="booking-curve">
    <section class="hero is-dark">
      <div class="hero-body">
        <div class="columns">
          <div class="column is-2">
            <figure class="image is-128x128">
              <img src="https://www.zipworld.co.uk/assets/img/2017/zip-logo.svg">
            </figure>
          </div>
          <div class="column is-8">
            <div class="container content">
              <h1 class="title is-1" >
                Booking Curve <span class="sub-text">v1.10</span>
              </h1>
              <h3 v-if="selectedProduct" class="subtitle is-3">
                {{selectedProduct.name}}
              </h3>
              <h5 v-if="selectedProduct" class="subtitle is-5">
                Activity date: {{ computedDateFormattedMomentjs }}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="">
      <chart :report-data="reportData" :selected-date="selectedDate" :selected-product="selectedProduct"></chart>
    </section>
    <section class="section">
      <div class="custom-panel">
        <div class="heading is-dark">
          <h5 class="subtitle is-5">
            Report criteria
          </h5>
        </div>
        <div class="body">
          <div class="columns">
            <div class="column is-3">

              <b-field label="Select activity">
                <b-select v-model="selectedProduct" placeholder="Select an activity" @input="updateSelectedProduct()">
                  <option
                    v-for="product in products"
                    :value="product"F
                    :key="product.value">
                    {{ product.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label="Select the activity date">
                <b-datepicker v-model="selectedDate"
                              :first-day-of-week="1"
                              placeholder="Click to select..."
                              :date-formatter="formatter"
                              :min-date="minActivityDate"
                              icon="calendar-today"
                              @input="updateSelectedDate()">

                  <button class="button is-primary"
                          @click="selectedDate = new Date()">
                    <b-icon icon="calendar-today"></b-icon>
                    <span>Today</span>
                  </button>

                  <button class="button is-danger"
                          @click="selectedDate = null">
                    <b-icon icon="close"></b-icon>
                    <span>Clear</span>
                  </button>
                </b-datepicker>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">


              <div class="message">
                <div class="message-header">
                  <button :disabled="extraCharts.length>4" class="button is-success"
                          @click="addNewReport()">
                    <b-icon icon="plus-box"></b-icon>
                    <span>Compare</span>
                  </button>
                </div>
                <div class="select-extra-dates message-body">
                  Compare with up to 5 different activity dates:<br/><br/>

                  <div class="box" v-for="(extraChart, key) in extraCharts">

                      <div class="field ">
                        <label class="label is-pulled-left">Select a date for comparison</label>
                        <span class="delete is-medium has-background-danger is-pulled-right" @click="deleteChart(key)"></span>
                        <b-datepicker
                          v-model="extraChart.date"
                          :first-day-of-week="1"
                          placeholder="Click to select..."
                          :date-formatter="formatter"
                          :min-date="minActivityDate"
                          icon="calendar-today"
                          @input="selectExtraChartDate(extraChart.date, key)">
                        </b-datepicker>
                      </div>

                  </div>

                </div>
              </div>

            </div>

          </div>
        </div>

        </div>


    </section>
  </div>
</template>

<script>
  import chart from '../components/Chart.vue';
  import {_} from 'vue-underscore';
  import * as moment from 'moment';
  import { EventBus } from '../event-bus.js';

  export default {
    components: {chart},
    name: 'booking-curve',
    props: ['signedIn', 'accessToken', 'email'],
    data () {
      return {
        //datepickerDate: new Date().toISOString().substr(0, 10),
        init: false,
        selected: 'chart',
        currentView: 'chart',
        reportData: [],
        selectedProduct: null,
        selectedDate: new Date(),
        chartTitle: '',
        products: [
          {name: 'Velocity 2', value: 'Velocity 2'},
          {name: 'Velocity 2 Camera', value: 'Velocity Camera'},
          {name: 'Quarry Karts', value: 'Quarry Karts'},
          {name: 'Big Red', value: 'Big Red'},
          {name: 'Quarry Tour', value: 'Quarry Tour'},
          {name: 'Penrhyn Meal', value: 'Penrhyn Meal'},
          {name: 'Titan', value: 'Titan'},
          {name: 'Titan Camera', value: 'Titan Camera'},
          {name: 'Bounce Below', value: 'Bounce Below'},
          {name: 'Bounce Below Spectator', value: 'Bounce Below Spectator'},
          {name: 'Caverns', value: 'Caverns'},
          {name: 'Fforest Coaster', value: 'Fforest Coaster'},
          {name: 'Fforest Coaster Camera', value: 'Coaster Camera'},
          {name: 'Zip Safari', value: 'Zip Safari'},
          {name: 'Skyride', value: 'Skyride'},
          {name: 'Plummet 2', value: 'Plummet'},
          {name: 'Tree Hoppers ', value: 'Tree Hoppers'},
          {name: 'Treetop nets', value: 'Treetop Nets'},
          {name: 'Zipworld Rocks', value: 'Zipworld_Rocks'}


        ],
        extraCharts : [],
        minActivityDate: new Date (2019, 0, 18),
      }
    },
    computed: {
      computedDateFormattedMomentjs () {
        return this.selectedDate ? moment(this.selectedDate).format('dddd, MMMM Do YYYY') : ''
      }
    },
    mounted: function () {

    },
    watch: {
      signedIn: function(value) {
        if (value && this.accessToken) {
          //console.log('signed in');
          this.initBookingCurve();
        }
      }
    },
    methods: {
      updateSelectedDate: function () {
        let date = this.selectedDate;
        if (date) {
          //console.log('sel date');
          let momentDate = moment(date);
          let dateStr = momentDate.format('YYYY-MM-DD');
          this.$router.push({path: '/booking-curve/' + this.$route.params.product + '/' + dateStr});
          this.fetchBookingCurve(0, dateStr);
        }
      },

      updateSelectedProduct: function() {
        //console.log('sel prod here');
        let value = this.selectedProduct;

        //delete extra charts:
        this.extraCharts = [];
        for (var i=1; i< this.reportData.length; i++) {
          Vue.delete(this.reportData, i);
        }
        if (value) {

          let date = this.selectedDate;
          if (date) {
            let momentDate = moment(date);
            let dateStr = momentDate.format('YYYY-MM-DD');
            this.$router.push({ path: '/booking-curve/'+value.value+'/' + dateStr });
            this.fetchBookingCurve(0, dateStr);
          } else {
            this.$router.push({path: '/booking-curve/' + value.value + '/'});
          }
        }
      },

      initBookingCurve: function() {
          this.getSelectedProduct(this.$route.params.product);
          this.setSelectedDate(this.$route.params.date);
          this.fetchBookingCurve(0, this.$route.params.date);
      },
      deleteChart: function(k) {
        this.extraCharts.splice(k, 1);
        Vue.delete(this.reportData, k+1);
        //this.reportData.splice(k+1,1);
      },
      selectExtraChartDate: function(d,k) {
        //console.log('ex date');

        let v = {date: d, name: 'Charty'};
        //Vue.set(this.extraDates, k, v);
        let momentDate = moment(d);
        let dateStr = momentDate.format('YYYY-MM-DD');
        this.fetchBookingCurve(k+1, dateStr);
      },
      addNewReport: function() {
        let newDate = new Date();
        let newChart = {date: newDate, name: 'Charty'}
        this.extraCharts.push(newChart);
      },
      formatter: function (d) {
        var date = moment(d);
        return date.format('ddd,  Do MMM, YYYY');
      },
      getSelectedProduct: function(value) {
        let product = _.where(this.products, {value:value});
        if (product && product.length > 0) {
          this.selectedProduct = product[0];
        } else {
          this.selectedProduct = null;
        }

      },
      setSelectedDate: function(dateString) {
        this.selectedDate =  dateString ? new Date(dateString) : new Date();
        //this.selectedDate = new Date(dateString);
      },
      activate (elem) {
        this.selected = elem
      },
      handler () {
        var args = arguments
        for (var arg of args) {
          if (arg instanceof Function) {
            arg()
          }
        }
      },
      select (elem) {
        this.currentView = elem
        this.activate(elem)
      },
      fetchBookingCurve: function (reportDataIndex, date) {
        EventBus.$emit('apiErrorClear');

        if (this.selectedProduct && this.accessToken && date) {
          const baseURI = ZW_CONFIG.API_URL + '/report/booking-curve/'+ this.selectedProduct.value+'/'+date;
          var _this = this;
          let config = {
            headers: {
              'Authorization': 'Bearer ' + this.accessToken
            }
          }
          const loadingComponent = this.$loading.open({
            container:  null
          });
          this.$http.get(baseURI, config)
            .then((result) => {
              let momentDate = moment(date);
              let name = this.selectedProduct.name + ": " + momentDate.format('dddd, MMMM Do YYYY');

              if (!result.data.result || result.data.result==0) {
                EventBus.$emit('apiError', 'No report data in the booking curve response.');
              }
              Vue.set(_this.reportData, reportDataIndex, {id: 'report_'+reportDataIndex, name: name, data: result.data.result, date: date});
              loadingComponent.close();

            }).catch(function (error) {
              //console.log(error.response);
              loadingComponent.close();
              if (error.response.status == 403) {
                EventBus.$emit('apiAuthFail');
              }
              if (error.response.status == 500) {
                EventBus.$emit('apiError', 'There was an error with the Booking Curve API request.');
              }
            });
        }
      }
    }
  }
</script>

