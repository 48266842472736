<template>
  <div class="chartElem" v-if="showChart">
    <highcharts class="chart" :options="chartOptions" :updateArgs="updateArgs"></highcharts>
  </div>
  <div v-else>
    <h5 class="title is-5">
      There is no data for the selected criteria
    </h5>
  </div>
</template>

<script>
  import * as moment from 'moment';
  import {_} from 'vue-underscore';


  export default {
    name: 'chart',
    props:['reportData', 'selectedDate', 'selectedProduct'],
    data () {
      return {
        showChart: true,
        showProjection: true,
        title: '',
        updateArgs: [true, true, {duration: 1000}],
        chartOptions: {
          chart: {
            type: 'line'
          },
          title: {
            text: ''
          },
          xAxis: [{
            categories: [],
            plotLines: [{
              color: '#FF0000',
              width: 2,
              value: 5.5
            }]
          }],
          series: [
            {
              data: [],
              color: '#6fcd98'
            }
          ]
        }
      }
    },
    created () {
    },
    watch: {
      reportData: function() {
        this.setChart();
      }
    },
    mounted: function() {

    },
    methods: {
      setChart: function () {
        //console.log('set chart');
        var displayedChartKeys = Object.keys(this.chartOptions.series);
        //console.log('keys');
        //console.log(displayedChartKeys);
        let selectedDate = moment(this.selectedDate);

        if (this.reportData[0]) {
          displayedChartKeys = _.without(displayedChartKeys, "0");
          var axis = [];
          var points = [];
          var date = null;
          var q=0;
          var i=0;
          for (i=0; i < this.reportData[0].data.length; i++) {
            var point = {};
            date = moment(this.reportData[0].data[i]['date']);
            if(date.isAfter(selectedDate)) {
              break;
            }
            point.y = this.reportData[0].data[i]['n'];
            point.name = date.format('dddd, MMMM Do YYYY');
            axis[i] = date.format('ddd, DD-MM-YY');
            points[i] = point;
          }
          var lastDateKey = i;
          let realPointsCount = points.length;

          //projection chart line
          if (date) {
            let diff = selectedDate.diff(date, 'days') - 1 ;
            if (diff > 0) {
              let projectionDateStep = (point.y-points[0].y)/ (realPointsCount-1);
              var projectioData = [];
              for (q=0; q<diff; q++) {
                lastDateKey = i+q;
                date.add(1, 'days');
                let dateStr = date.format('ddd, DD-MM-YY');
                axis[lastDateKey] = dateStr;
                points[lastDateKey] = null;

                let projectionPoint = [lastDateKey, points[realPointsCount-1].y + (projectionDateStep*(q+1))];
                projectioData.push(projectionPoint);

              }

              if (projectioData.length>0 && this.showProjection) {
                this.chartOptions.series[1] = {data: projectioData, color: 'blue', name: this.reportData[0].name +' PROJECTION', dashStyle: 'dot', visible: false};
                displayedChartKeys = _.without(displayedChartKeys, "1");
              }

            }
          }
          if (axis.length>0 && realPointsCount>0) {

            this.showChart = true;
            this.chartOptions.series[0].data = points;
            this.chartOptions.series[0].name = this.reportData[0].name;

            this.chartOptions.xAxis[0].categories = axis;

            if (lastDateKey-1 > 0) {
              this.chartOptions.xAxis[0].plotLines = [{
                color: '#FF0000',
                width: 2,
                value: lastDateKey,
                label: {
                  text: date.format('dddd, MMMM Do YYYY'), // Content of the label.
                  align: 'left' // Positioning of the label.
                }
              }];
            }
          } else {
            this.showChart = false;
          }

        }

        //add compare data
        let xAxisLength = this.chartOptions.xAxis[0].categories.length;

        for (var c=1; c<this.reportData.length; c++) {
          var thisData = [];
          let dataCnt = this.reportData[c].data.length;

          //less data than current axis
          if (dataCnt < xAxisLength) {

            var missingValCount = 0;
            if (selectedDate.isAfter(moment(this.reportData[c].date))) {
              missingValCount = xAxisLength-dataCnt;
            } else {
              let dateDiff = selectedDate.diff(moment(), 'days');
              missingValCount = xAxisLength-dataCnt-dateDiff-1;
            }
            //pad out with null values if we selected a comparison date in the past or equal.

            for (var z=(0-missingValCount); z<(xAxisLength-missingValCount); z++) {
              if(this.reportData[c].data[z]) {
                let dataDate = moment(this.reportData[c].data[z].date);
                let dataPoint = {y:this.reportData[c].data[z].n, name: dataDate.format('dddd, MMMM Do YYYY')};

                thisData.push(dataPoint);
              } else {
                thisData.push(null);
              }
            }
          } else if (dataCnt > xAxisLength) {
            //more data than current axis

            //for when the comparison has more points than the selected adventure date.
            //need to trim data off beginning of the list
            let extraValCount = dataCnt - xAxisLength;
            for (var z=0; z<dataCnt; z++) {
              if (z >= extraValCount) {
                if(this.reportData[c].data[z]) {
                  let dataDate = moment(this.reportData[c].data[z].date);
                  let dataPoint = {y:this.reportData[c].data[z].n, name: dataDate.format('dddd, MMMM Do YYYY')};

                  thisData.push(dataPoint);
                }
              }
            }
          } else {
            //data count equals axis size
            for (var z=0; z<dataCnt; z++) {
              if(this.reportData[c].data[z]) {
                let dataDate = moment(this.reportData[c].data[z].date);
                let dataPoint = {y:this.reportData[c].data[z].n, name: dataDate.format('dddd, MMMM Do YYYY')};

                thisData.push(dataPoint);
              }
            }
          }

          this.chartOptions.series[c+1] = {data:thisData, name:this.reportData[c].name};
          displayedChartKeys = _.without(displayedChartKeys, (c+1).toString());
        }
        for(var e=0; e<displayedChartKeys.length; e++) {
           Vue.delete(this.chartOptions.series, displayedChartKeys[e]);

        }
      }
    }
  }
</script>

<style>



</style>

