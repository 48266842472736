import Vue from 'vue/dist/vue.js';
import App from './App.vue';

import VueRouter from 'vue-router'
Vue.use(VueRouter);

import axios from 'axios';
Vue.prototype.$http = axios

import HighchartsVue from 'highcharts-vue';
import Highcharts from 'highcharts';
Vue.use(HighchartsVue);

import Buefy from 'buefy';
import 'buefy/dist/buefy.css';
Vue.use(Buefy);

import BookingCurve from './views/BookingCurve.vue';

import { LoaderPlugin } from 'vue-google-login';


const routes = [
  { path: '/', redirect: '/booking-curve'},
  { path: '/booking-curve/:product?/:date?', component: BookingCurve }
];

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = new VueRouter({
  routes // short for `routes: routes`
});

window.Vue = Vue;

//set project config
if (PROJECT == 'live') {
   window.ZW_CONFIG = {
    CLIENT_ID: '99948105953-iqgn4r6t8tg0odl2lhm5fr4sib29qvf8.apps.googleusercontent.com',
    API_URL:  'https://mi-dot-live-195212.appspot.com'
  }
} else if(PROJECT == 'dev') {
  window.ZW_CONFIG = {
    CLIENT_ID: '1056308140606-u5ek1qp60f6v88d77hpv28cq2pr4h0t8.apps.googleusercontent.com',
    API_URL: 'https://mi-dot-development-194013.appspot.com'
  }
} else {
  window.ZW_CONFIG = {
    CLIENT_ID: '99948105953-iqgn4r6t8tg0odl2lhm5fr4sib29qvf8.apps.googleusercontent.com',
    API_URL: 'http://margot.local:83'
  }
}


Vue.use(LoaderPlugin, {
  client_id: ZW_CONFIG.CLIENT_ID
});

new Vue({
  router,
  //components: {GoogleLogin},
  el: '#app',
  render: h => h(App)
});
