<template>
  <div id="app">
    <div v-if="!signedIn">
      <section class="hero is-dark">
        <div class="hero-body">
          <div class="columns">
            <div class="column is-2">
              <figure class="image is-128x128">
                <img src="https://www.zipworld.co.uk/assets/img/2017/zip-logo.svg">
              </figure>
            </div>
            <div class="column is-10">
              <div class="container content">
                <h1 class="title is-4" >
                  Report Viewer
                </h1>
                <GoogleLogin :params="{client_id: clientId}" v-if="!accessToken" class="loginBtn-google" :client_id="clientId" :onSuccess="onSuccess" :onFailure="onFailure">Login with Google</GoogleLogin>
                <GoogleLogin v-else :params="{client_id: clientId}" class="loginBtn-google" :client_id="clientId" :logoutButton=true :onSuccess="onLogout">Logout of Google</GoogleLogin>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

        <div v-if="signedIn && email" class="logged-in">
          Logged in as {{ email }}
          <GoogleLogin class="loginBtn-google-silent" :params="{client_id: clientId}" :logoutButton=true :onSuccess="onLogout">Logout of Google</GoogleLogin>
        </div>

    <router-view :email="email" :signedIn="signedIn" :accessToken="accessToken"></router-view>
  </div>
</template>

<script>

  import GoogleLogin from 'vue-google-login';
  import { EventBus } from './event-bus.js';

  export default {
    name: 'app',
    components: {GoogleLogin},
    watch: {
      msg: function(text) {
        if (text != '') {
          let _this = this;
          this.$snackbar.open({
            indefinite: true,
            message: text,
            type: 'is-danger',
            position: 'is-top-right',
            actionText: 'close',
            queue: false,
            onAction: () => {
              _this.msg = '';
            }
          })
        }
      }
    },
    data() {
      return {
        clientId: ZW_CONFIG.CLIENT_ID,
        accessToken: null,
        signedIn: false,
        msg: '',
        email: null
      }
    },
    mounted: function () {
      Vue.GoogleAuth.then(auth2 => {
        let googleSignedIn = auth2.isSignedIn.get();
        if (googleSignedIn) {
          //console.log('LOGGED IN');
          let googleUser = auth2.currentUser.get();
          this.email = googleUser.getBasicProfile().getEmail();
          //let authResponse = googleUser.getAuthResponse(true);
          //console.log('AUTH RESPONSE x');

          let token = googleUser.getAuthResponse(true).id_token;
          this.accessToken = token;
          this.apiAuth();
        } else {
          this.signedIn = false;
          this.accessToken = null;
          //console.log('NOT LOGGED IN');
        }

      });

      let _this = this;
      EventBus.$on('apiAuthFail', function(msg) {
        _this.signedIn = false;
        _this.accessToken = null;
        _this.msg = 'You are no longer authorised. Please login.'
      });

      EventBus.$on('apiError', function(msg) {
        _this.msg = msg;
      });

      EventBus.$on('apiErrorClear', function() {
          var btn = document.querySelectorAll('.snackbar .button');
          if (btn) {
            if (btn[0]) {
              btn[0].click();
            }
          }

      });

    },
    methods: {
      handler() {
        var args = arguments
        for (var arg of args) {
          if (arg instanceof Function) {
            arg()
          }
        }
      },
      onLogout() {
        this.accessToken = null;
        this.signedIn = false;
        this.email = null;
        //console.log('LOGed out');

      },
      onSuccess(googleUser) {
        console.log(googleUser);
        let authResponse = googleUser.getAuthResponse(true);
        this.accessToken = authResponse.id_token;
        this.email = googleUser.getBasicProfile().getEmail();
        //console.log('LOGIN SUCCESS');
        //console.log(authResponse);

        this.apiAuth();
        //now check api auth

        // This only gets the user information: id, name, imageUrl and email
        //console.log(googleUser.getBasicProfile());
      },

      apiAuth: function () {
        if (this.accessToken) {
          const baseURI = ZW_CONFIG.API_URL + '/report/auth';
          var _this = this;
          let config = {
            headers: {
              'Authorization': 'Bearer ' + this.accessToken
            }
          }
          this.$http.get(baseURI, config)
            .then((result) => {
              if (result.data.success == true) {
                this.signedIn = true;
              } else {
                this.signedIn = false;
              }

          }).catch(function (error) {
            //console.log(error.response);
            if (error.response.status == 403) {
              _this.msg  = 'This google user is not authorised. You may try to login as another google user.';
            }
          });

        }
      }
    }
  }
</script>

<style>
  .snackbar {
    border: 2px solid #ff3860;
  }
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: left;
    color: #2c3e50;
    margin: 0 auto;
    margin-top: 0px;
    width: 100%;
  }
  h1, h2 {
    font-weight: normal;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .row > * {
    padding: 0 20px;
  }
  .logos {
    margin: 0 auto;
    width:50%;
  }
  .logo-hc {
    width: 70%;
    height: auto;
  }
  .logo-vue {
    width: 15%;
    height: auto;
  }
  .button-grp {
    margin: 20px 0;
  }
  button {
    padding: 10px 20px;
    outline: none;
    background: #828ea0;
    color: #fff;
    font-size: 16px;
    margin: 5px;
    border: 0px;
  }
  button:hover {
    background: #a7aeb8;
    transition: background 0.2s;
  }
  button:active {
    color: rgb(117, 117, 117);
  }
  .btnActive {
    color: #6fcd98
  }

  .chartElem {
    margin: 40px 20px;
  }

  .actions {
    margin: 30px 20px;
  }

  div.custom-panel{
    border: 1px solid #dbdbdb;
    margin-bottom: 1.5rem;
    -webkit-border-radius: 3px 3px 0 0;
    -moz-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0;
  }

  div.custom-panel div.heading{
    background-color: #f5f5f5;
    color: #363636;
    font-size: 1.25em;
    font-weight: 300;
    line-height: 1.25;
    padding: .5em .75em;
    border-bottom: 1px solid #dbdbdb;
    text-transform: none !important;
  }

  div.custom-panel div.foot{
    padding: .5em .75em;
    border-top: 1px solid #dbdbdb;
  }

  div.custom-panel div.body{
    padding: .5em .75em;
  }

  .select-extra-dates .box {
    margin-bottom: 10px;
  }

  .loginBtn-google {
    font-family: Roboto,arial,sans-serif;
    padding: 15px 10px 15px 50px;
    background-image: url(/src/assets/google.png);
    background-repeat: no-repeat;
    background-size: 35px auto;
    background-position: 5px 5px;
    background-color: #fff;
    color: #666666;
    border-radius: 3px;
    pointer: cursor;
  }

  .loginBtn-google:hover,
  .loginBtn-google:focus {
    font-family: Roboto,arial,sans-serif;
    padding: 15px 10px 15px 50px;
    background-image: url(/src/assets/google.png);
    background-repeat: no-repeat;
    background-size: 35px auto;
    background-position: 5px 5px;
    background-color: #fff;
    color: #666666;
    border-radius: 3px;
    cursor: pointer;
    opacity: 0.8;
  }



  .loginBtn-google-silent {
    font-family: Roboto,arial,sans-serif;
    padding: 0px;
    color: lime;
    cursor: pointer;
    font-size: 11pt;
    background: transparent;
  }

  .loginBtn-google-silent:hover,
  .loginBtn-google-silent:focus {
    font-family: Roboto,arial,sans-serif;
    color: #ffffff;
    cursor: pointer;
    font-size: 11pt;
    background: transparent;
  }



  .logged-in {
    background-color: #363636;
    color: #f5f5f5;
    text-align: right;
    padding-right: 20px;
    font-size: 11pt;
  }

  #booking-curve {
    margin-top: 0px;
  }

  .sub-text {
    font-size: 10px;
    color: darkgray;
  }
</style>
